import axios from 'axios';

import {
  InstagramAudienceHistoryMetricsModel,
  InstagramAudienceMetricsModel,
  MetricsChartModel,
  MetricsPlatformArtistModel,
  MetricsPlaylistsModel,
  PlatformOverviewMetricsModel,
  SpotifyAudienceLocationsMetricsModel,
  YoutubeAudienceMetricsModel,
  YoutubeMarketCoverageMetrics,
} from '@/models/Metrics';

class MetricsAPI {
  static getPlatformArtistMetrics = async ({
    chartmetricId,
    platform,
    fromDate,
    toDate,
  }: {
    chartmetricId: number;
    platform: string;
    fromDate: string;
    toDate: string;
  }) =>
    await axios.get<MetricsPlatformArtistModel>(
      `metrics/chartmetric/artist/${chartmetricId}/stat/${platform}?fromDate=${fromDate}&toDate=${toDate}`
    );

  static getTrackIdFromSpotifyId = async ({ spotifyId }: { spotifyId: string }) =>
    await axios.get<{ chartmetric_ids: number[] }>(`/metrics/chartmetric/track/spotify/${spotifyId}/get-ids`);

  static getPlaylistTrackStatsThroughTime = async ({
    chartmetricId,
    fromDate,
    toDate = '',
  }: {
    chartmetricId: number;
    fromDate: string;
    toDate?: string;
  }) =>
    await axios.get<MetricsChartModel>(
      `/metrics/chartmetric/playlist/track/${chartmetricId}/evolution?fromDate=${fromDate}&toDate=${toDate}`
    );

  static getPlaylistArtistStatsThroughTime = async ({
    chartmetricId,
    fromDate,
    toDate = '',
  }: {
    chartmetricId: number;
    fromDate: string;
    toDate?: string;
  }) =>
    await axios.get<MetricsChartModel>(
      `/metrics/chartmetric/playlist/artist/${chartmetricId}/evolution?fromDate=${fromDate}&toDate=${toDate}`
    );

  static getStaticInstagramAudienceMetrics = async ({ chartmetricId }: { chartmetricId: number }) =>
    await axios.get<InstagramAudienceMetricsModel>(
      `/metrics/chartmetric/artist/${chartmetricId}/instagram-audience-stats`
    );

  static getInstagramAudienceHistoricMetrics = async ({
    chartmetricId,
    fromDate,
  }: {
    chartmetricId: number;
    fromDate?: string;
  }) =>
    await axios.get<{ metrics: InstagramAudienceHistoryMetricsModel[] }>(
      `metrics/chartmetric/artist/${chartmetricId}/social-audience-stats${fromDate ? `?fromDate=${fromDate}` : ''}`
    );

  static getStaticYoutubeAudienceMetrics = async ({ chartmetricId }: { chartmetricId: number }) =>
    await axios.get<YoutubeAudienceMetricsModel>(`/metrics/chartmetric/artist/${chartmetricId}/youtube-audience-stats`);

  static getSpotifyMonthlyListenerLocations = async ({
    chartmetricId,
    limit = 10,
  }: {
    chartmetricId: number;
    limit: number;
  }) =>
    await axios.get<SpotifyAudienceLocationsMetricsModel>(
      `metrics/chartmetric/artist/${chartmetricId}/where-people-listen?limit=${limit}`
    );

  static getPlaylists = async ({
    chartmetricId,
    platform,
    limit = 100,
    fromDate = '',
    offset = 0,
  }: {
    chartmetricId: number;
    platform: string;
    limit?: number;
    fromDate?: string;
    offset?: number;
  }) =>
    await axios.get<MetricsPlaylistsModel[]>(
      `metrics/chartmetric/artist/${chartmetricId}/${platform}/current/playlists?limit=${limit}&fromDate=${fromDate}&offset=${offset}`
    );

  static getYoutubeViewsLocations = async ({ chartmetricId }: { chartmetricId: number }) =>
    await axios.get<YoutubeMarketCoverageMetrics>(
      `/metrics/chartmetric/artist/${chartmetricId}/market-coverage-views/youtube`
    );

  static getOverviewMetrics = async ({ chartmetricId, type }: { chartmetricId: number; type: 'social' | 'music' }) =>
    await axios.get<PlatformOverviewMetricsModel>(`/metrics/chartmetric/artist/overview/${chartmetricId}/${type}`);
}

export default MetricsAPI;
