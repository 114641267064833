import axios from 'axios';

import { InsightsModel } from '@/models/Insights';

class InsightsAPI {
  static getBenchmarkInsight = async ({
    platform,
    type,
    artistId,
  }: {
    platform: string;
    type: string;
    artistId?: string;
  }) => await axios.get<InsightsModel>(`/insights/benchmark/${platform}/${type}/${artistId}`);
}

export default InsightsAPI;
