import axios from 'axios';

import { AdReportDataModel, AdsPotentialReachModel } from '@/models/Adverts';
import {
  TikTokAdsFormAdAccountModel,
  TikTokAdsFormAdModel,
  TikTokAdsFormModel,
  TikTokAdsInterestModel,
  TikTokAdsLocationModel,
  TikTokAdsUserVideosModel,
  TikTokAdsVideoModel,
  TikTokAdvertiserIdModel,
  TikTokAdvertModel,
  TikTokIdentityModel,
  TikTokTargetingSuggestionsModel,
} from '@/models/TikTok';

class TikTokAPI {
  static loginToTikTok = async ({ accountId, authCode }: { accountId: string; authCode: string }) =>
    await axios.post('/advert/tiktok/login', { accountId, authCode });

  static logoutOfTikTok = async ({ accountId }: { accountId: string }) =>
    await axios.post(`advert/tiktok/logout/${accountId}`, { accountId, accessToken: '' });

  static getTikTokIdentityList = async ({ accountId, advertiserId }: { accountId: string; advertiserId: string }) =>
    await axios.get<TikTokIdentityModel>(`/advert/tiktok/identity/${accountId}/${advertiserId}`);

  static getTikTokAdvertisingId = async ({ accountId }: { accountId: string }) =>
    await axios.get<TikTokAdvertiserIdModel>(`/advert/tiktok/account/${accountId}`);

  static searchTikTokLocations = async ({
    accountId,
    advertiserId,
    goal,
    search = '',
  }: {
    accountId: string;
    advertiserId: string;
    goal: string;
    search?: string;
  }) =>
    await axios.get<TikTokAdsLocationModel[]>(
      `/advert/tiktok/location/${accountId}/${advertiserId}?campaignGoal=${goal}&search=${search}`
    );

  static getTikTokLocationRecommendations = async ({
    accountId,
    advertiserId,
    goal,
  }: {
    accountId: string;
    advertiserId: string;
    goal: string;
  }) =>
    await axios.get<TikTokAdsLocationModel[]>(
      `/advert/tiktok/location/recommendation/${accountId}/${advertiserId}?campaignGoal=${goal}`
    );

  static searchTikTokInterests = async ({
    accountId,
    advertiserId,
    search = '',
  }: {
    accountId: string;
    advertiserId: string;
    search?: string;
  }) =>
    await axios.get<TikTokAdsInterestModel[]>(`/advert/tiktok/interest/${accountId}/${advertiserId}?search=${search}`);

  static getTikTokInterestRecommendations = async ({
    accountId,
    advertiserId,
  }: {
    accountId: string;
    advertiserId: string;
  }) =>
    await axios.get<TikTokAdsInterestModel[]>(`/advert/tiktok/interest/recommendation/${accountId}/${advertiserId}`);

  static getTikTokInterestSuggestions = async ({
    accountId,
    advertiserId,
    selectedKeywords = '',
  }: {
    accountId: string;
    advertiserId: string;
    selectedKeywords?: string;
  }) =>
    await axios.get<TikTokAdsInterestModel[]>(
      `/advert/tiktok/interest/suggest/${accountId}/${advertiserId}?selectedKeywords=${selectedKeywords}`
    );

  static getTikTokUserVideos = async ({
    accountId,
    advertiserId,
    identityId,
  }: {
    accountId: string;
    advertiserId: string;
    identityId: string;
  }) =>
    await axios.get<TikTokAdsUserVideosModel>(
      `/advert/tiktok/identity/video/${accountId}/${advertiserId}/${identityId}`
    );

  static getTikTokPotentialReach = async ({
    accountId,
    ad,
    adAccount,
  }: {
    accountId: string;
    ad: TikTokAdsFormAdModel;
    adAccount: TikTokAdsFormAdAccountModel;
  }) => await axios.post<AdsPotentialReachModel>('advert/tiktok/audience/size', { accountId, ad, adAccount });

  static createTikTokAdDraft = async (data: TikTokAdsFormModel) =>
    await axios.post<{ id: string }>('advert/tiktok/draft', data);

  static createTikTokAd = async (data: TikTokAdsFormModel) => await axios.post('advert/tiktok', data);

  static getTikTokAdVideo = async ({
    accountId,
    advertiserId,
    identityId,
    tikTokVideoId,
  }: {
    accountId: string;
    advertiserId?: string;
    identityId?: string;
    tikTokVideoId?: string;
  }) =>
    await axios.get<TikTokAdsVideoModel>(
      `/advert/tiktok/identity/video/${accountId}/${advertiserId}/${identityId}/${tikTokVideoId}`
    );

  static getTikTokAdvert = async ({ accountId, advertId }: { accountId: string; advertId: string }) =>
    await axios.get<TikTokAdvertModel>(`/advert/tiktok/${accountId}/${advertId}`);

  static updateTikTokCampaign = async ({
    accountId,
    advertId,
    data,
  }: {
    accountId: string;
    advertId: string;
    data: { status: string };
  }) => await axios.put<TikTokAdvertModel>(`/advert/tiktok/${accountId}/${advertId}`, data);

  static getTikTokAdvertMetrics = async ({ accountId, campaignId }: { accountId: string; campaignId: string }) =>
    await axios.get<AdReportDataModel>(`advert/tiktok/report/${accountId}/${campaignId}`);

  static getTikTokTargetingSuggestions = async ({
    accountId,
    advertiserId,
    goal,
  }: {
    accountId: string;
    advertiserId: string;
    goal?: string;
  }) =>
    await axios.get<TikTokTargetingSuggestionsModel>(
      `/advert/tiktok/targeting/recommendation/${accountId}/${advertiserId}?goal=${goal}`
    );
}

export default TikTokAPI;
