import axios from 'axios';

import { AccountModel, CreateAccountModel, UpdateAccountModel } from '@/models/Account';

class AccountAPI {
  static getAccount = async ({ firebaseAccountId }: { firebaseAccountId?: string }) =>
    await axios.get<AccountModel>(`/account/identity/${firebaseAccountId}`);

  static createAccount = async ({ data }: { data: CreateAccountModel }) =>
    await axios.post<AccountModel>('/account', data);

  static updateAccount = async ({ accountId, data }: { accountId: string; data: UpdateAccountModel }) =>
    await axios.put(`/account/${accountId}`, data);

  static deleteAccount = async ({ accountId }: { accountId: string }) => await axios.delete(`/account/${accountId}`);
}

export default AccountAPI;
