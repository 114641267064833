import axios from 'axios';

import {
  SpotifyArtistModel,
  SpotifyMusicModel,
  SpotifySearchDataItemsModel,
  SpotifySearchModel,
} from '@/models/Spotify';

class SpotifyAPI {
  static getSpotifyArtist = async ({
    spotifyArtistId,
    spotifyArtistUrl,
  }: {
    spotifyArtistId?: string;
    spotifyArtistUrl?: string;
  }) => {
    let url = '/spotify/artist';

    if (spotifyArtistId) {
      url = `${url}?spotifyArtistId=${spotifyArtistId}`;
    }

    if (spotifyArtistUrl) {
      url = `${url}?spotifyArtistUrl=${spotifyArtistUrl}`;
    }

    return await axios.get<SpotifyArtistModel>(url);
  };

  static searchArtist = async ({ query }: { query?: string }) =>
    await axios.get(`/spotify/search?query=${query || ''}&type=artist&limit=50&offset=0`);

  static getArtistSingles = async ({
    spotifyId,
    getAlbumTracks = true,
    limit = 20,
    offset = 0,
  }: {
    spotifyId: string;
    getAlbumTracks?: boolean;
    limit?: number;
    offset?: number;
  }) =>
    await axios.get<SpotifyMusicModel[]>(
      `/spotify/artist/${spotifyId}/single?getAlbumTracks=${getAlbumTracks}&limit=${limit}&offset=${offset}`
    );

  static getArtistAlbums = async ({
    spotifyId,
    getAlbumTracks = true,
    limit = 20,
    offset = 0,
  }: {
    spotifyId: string;
    getAlbumTracks?: boolean;
    limit?: number;
    offset?: number;
  }) =>
    await axios.get<SpotifyMusicModel[]>(
      `spotify/artist/${spotifyId}/album?getAlbumTracks=${getAlbumTracks}&limit=${limit}&offset=${offset}`
    );

  static searchArtistTrack = async ({ artistName, search = '' }: { artistName: string; search: string }) =>
    await axios.get<SpotifySearchModel>(
      `spotify/search?query=artist:${artistName} track:${search}&type=track&limit=20&offset=0`
    );

  static getArtistTopTracks = async ({ spotifyId }: { spotifyId: string }) =>
    await axios.get<SpotifySearchDataItemsModel[]>(`spotify/artist/${spotifyId}/top-tracks`);

  static getArtistSimilarArtists = async ({ spotifyId }: { spotifyId: string }) =>
    await axios.get<SpotifyArtistModel[]>(`spotify/artist/${spotifyId}/similar-artists`);
}

export default SpotifyAPI;
