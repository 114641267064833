import axios from 'axios';

import { FacebookAdImagesModel, MetaAdVideoDataModel } from '@/models/Meta';

class FacebookAPI {
  static getVideoDetailsById = async ({ videoId, accessToken }: { videoId: string; accessToken: string }) =>
    await axios.get(
      `${import.meta.env.VITE_FACEBOOK_IMAGE_URL}/${videoId}?fields=id,description,source,embed_html,thumbnails`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

  static uploadImage = async ({ adAccountId, data }: { adAccountId: string; data: FormData }) =>
    await axios.post<FacebookAdImagesModel>(`${import.meta.env.VITE_FACEBOOK_IMAGE_URL}/${adAccountId}/adimages`, data);

  static uploadVideo = async ({
    adAccountId,
    data,
    accessToken,
  }: {
    adAccountId: string;
    data: FormData;
    accessToken: string;
  }) =>
    await axios.post<MetaAdVideoDataModel>(`${import.meta.env.VITE_FACEBOOK_VIDEO_URL}/${adAccountId}/advideos`, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
}

export default FacebookAPI;
