import axios from 'axios';

import { TodoAccountDataModel, TodoAccountModel } from '@/models/Todo';

class ToDoAPI {
  static getPriorityToDos = async ({ accountId }: { accountId: string }) =>
    await axios.get<TodoAccountDataModel[]>(`/todo/${accountId}/priority`);

  static updateToDo = async ({
    accountId,
    todoId,
    data,
  }: {
    accountId: string;
    todoId: string;
    data: { status: string };
  }) => await axios.put(`/todo/${accountId}/${todoId}`, data);

  static getAccountToDos = async ({
    accountId,
    status,
    pageSize = 10,
    continuationToken = '',
  }: {
    accountId: string;
    status: string;
    pageSize?: number;
    continuationToken?: string;
  }) =>
    await axios.get<TodoAccountModel>(
      `/todo/${accountId}?status=${status}&pageSize=${pageSize}&continuationToken=${continuationToken}`
    );
}

export default ToDoAPI;
