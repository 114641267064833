import axios from 'axios';

import { AdReportModel, AdsPotentialReachAPIModel } from '@/models/Adverts';
import {
  MetaAdImagesModel,
  MetaAdsAccountModel,
  MetaAdsFormModel,
  MetaAdsInterestsModel,
  MetaAdsLanguagesSearchModel,
  MetaAdsLocationSearchModel,
  MetaAdsPagesModel,
  MetaAdsVerifyModel,
  MetaAdvertModel,
  MetaAdVideoModel,
  MetaCreativeModel,
  MetaTargetingModel,
  MetaTargetingSuggestionsModel,
} from '@/models/Meta';

class MetaAPI {
  static loginToMeta = async ({ accountId, accessToken }: { accountId: string; accessToken: string }) =>
    await axios.post('/advert/meta/login/web', { accountId, accessToken });

  static logoutOfMeta = async ({ accountId }: { accountId: string }) =>
    await axios.post(`advert/meta/logout/${accountId}`, { accountId, accessToken: '' });

  static getMetaAdAccounts = async ({ accountId }: { accountId: string }) =>
    await axios.get<MetaAdsAccountModel>(`/advert/meta/account/${accountId}?limit=100&sort&after`);

  static getMetaPages = async ({ accountId }: { accountId: string }) =>
    await axios.get<MetaAdsPagesModel>(`/advert/meta/page/${accountId}?limit=50&sort&after`);

  static verifyMetaIds = async ({
    accountId,
    adAccountId,
    facebookPageId,
    instagramPageId,
  }: {
    accountId: string;
    adAccountId: string;
    facebookPageId: string;
    instagramPageId?: string;
  }) =>
    await axios.post<MetaAdsVerifyModel>(`/advert/meta/verify-account-selections/${accountId}`, {
      adAccountId,
      facebookPageId,
      instagramPageId,
    });

  static searchMetaLocations = async ({ accountId, search = 'United' }: { accountId: string; search: string }) =>
    await axios.get<MetaAdsLocationSearchModel>(
      `/advert/meta/location/${accountId}?search=${search}&locationTypes=["city","country","region","countryGroup"]&limit=25&after`
    );

  static searchMetaLanguages = async ({ accountId, search = 'en' }: { accountId: string; search: string }) =>
    await axios.get<MetaAdsLanguagesSearchModel>(`/advert/meta/locale/${accountId}?search=${search}&limit=25&after`);

  static getMetaInterestRecommendations = async ({
    accountId,
    adAccountId,
  }: {
    accountId: string;
    adAccountId: string;
  }) => await axios.get<MetaAdsInterestsModel[]>(`/advert/meta/interest/recommendation/${accountId}/${adAccountId}`);

  static getMetaInterestSuggestions = async ({
    accountId,
    adAccountId,
    interests,
  }: {
    accountId: string;
    adAccountId: string;
    interests: { id: string; type: string }[];
  }) =>
    await axios.post<{ data: MetaAdsInterestsModel[] }>(
      `/advert/meta/interest/suggest/${accountId}/${adAccountId}`,
      interests
    );

  static searchMetaTargetAudiences = async ({
    accountId,
    adAccountId,
    search = '',
  }: {
    accountId: string;
    adAccountId: string;
    search: string;
  }) =>
    await axios.get<{ data: MetaAdsInterestsModel[] }>(
      `/advert/meta/audience/${accountId}/${adAccountId}?search=${search}&limit=25&after`
    );

  static getMetaAdImages = async ({ accountId, adAccountId }: { accountId: string; adAccountId?: string }) =>
    await axios.get<MetaAdImagesModel>(`/advert/meta/image/${accountId}/${adAccountId}?limit=100`);

  static getMetaAdVideos = async ({ accountId, adAccountId }: { accountId: string; adAccountId?: string }) =>
    await axios.get<MetaAdVideoModel>(`/advert/meta/videos/${accountId}/${adAccountId}?limit=100`);

  static getPotentialReachEstimate = async ({
    accountId,
    adAccountId,
    platforms,
    targeting,
  }: {
    accountId: string;
    adAccountId: string;
    platforms: string[];
    targeting: MetaTargetingModel;
  }) =>
    await axios.post<AdsPotentialReachAPIModel>(`advert/meta/audience/estimate/${accountId}/${adAccountId}`, {
      platforms,
      targeting,
    });

  static createMetaAdDraft = async (data: MetaAdsFormModel) =>
    await axios.post<{ id: string }>('advert/meta/draft', data);

  static createMetaAd = async (data: MetaAdsFormModel) => await axios.post('advert/meta', data);

  static getMetaCreative = async ({ accountId, advertId }: { accountId: string; advertId: string }) =>
    await axios.get<MetaCreativeModel>(`/advert/meta/creative/${accountId}/${advertId}`);

  static getMetaAdvert = async ({ accountId, advertId }: { accountId: string; advertId: string }) =>
    await axios.get<MetaAdvertModel>(`/advert/meta/${accountId}/${advertId}`);

  static updateMetaCampaign = async ({
    accountId,
    advertId,
    data,
  }: {
    accountId: string;
    advertId: string;
    data: { status: string; facebookAccessToken: string };
  }) => await axios.put(`/advert/meta/${accountId}/${advertId}`, data);

  static getMetaAdReport = async ({ accountId, campaignId }: { accountId: string; campaignId: string }) =>
    await axios.get<AdReportModel>(`/advert/meta/report/${accountId}/${campaignId}?limit=10&sort&after`);

  static getMetaTargetingSuggestions = async ({ accountId }: { accountId: string }) =>
    await axios.get<MetaTargetingSuggestionsModel>(`/advert/meta/targeting/recommendation/${accountId}`);
}

export default MetaAPI;
