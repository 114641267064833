import axios from 'axios';

import { WebsiteBuilderModel, WebsiteBuilderResponseModel } from '@/models/WebsiteBuilder';

class WebsiteBuilderAPI {
  static getWebsiteByAccountId = async ({ accountId }: { accountId: string }) =>
    await axios.get<WebsiteBuilderModel>(`website-builder/account/${accountId}`);

  static createWebsite = async ({ data }: { data: WebsiteBuilderModel }) =>
    await axios.post<WebsiteBuilderResponseModel>('/website-builder', data);

  static updateWebsite = async ({ data }: { data: WebsiteBuilderModel }) =>
    await axios.put<WebsiteBuilderResponseModel>('/website-builder', data);

  static validateUniqueArtist = async ({ artistId }: { artistId: string }) =>
    await axios.get<boolean>(`/website-builder/artist/${artistId}/validate`);

  static enhanceBio = async ({ artistId, bio }: { artistId: string; bio: string }) =>
    await axios.post<string[]>(`/website-builder/bio/enhance/${artistId}`, { bio });

  static checkURLValid = async ({ route }: { route: string }) =>
    await axios.get<boolean>(`/website-builder/route/${route}/validate`);

  static exportMailingList = async ({ mailingListId }: { mailingListId: string }) =>
    await axios.get<Blob>(`/mailing-list/${mailingListId}/export`, {
      headers: { Accept: 'application/octet-stream' },
    });

  static deleteFanHub = async ({ accountId }: { accountId: string }) =>
    await axios.delete(`/website-builder/account/${accountId}`);
}

export default WebsiteBuilderAPI;
