import axios from 'axios';

import { ActivityModel } from '@/models/Activity';

class ActivityAPI {
  static getAccountActivity = async ({
    accountId,
    pageSize = 10,
    continuationToken = '',
  }: {
    accountId: string;
    pageSize?: number;
    continuationToken?: string;
  }) =>
    await axios.get<ActivityModel>(
      `/activity/${accountId}?pageSize=${pageSize}&continuationToken=${continuationToken}`
    );
}

export default ActivityAPI;
