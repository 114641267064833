import axios from 'axios';

import { PagesAdvertsModel } from '@/models/Adverts';
import { ActiveCampaignsModel } from '@/models/Campaigns';

class AdvertAPI {
  static getPagesAdverts = async ({
    accountId,
    status = 'active',
    sortedBy = 'newest',
    offset = 0,
    limit = 50,
  }: {
    accountId?: string;
    status?: string;
    sortedBy?: string;
    offset?: number;
    limit?: number;
  }) =>
    await axios.get<PagesAdvertsModel>(
      `/advert/account/${accountId}?status=${status}&sortedBy=${sortedBy}&offset=${offset}&limit=${limit}`
    );

  static getActiveCampaigns = async ({ accountId }: { accountId?: string }) =>
    await axios.get<ActiveCampaignsModel>(`/campaign/${accountId}/overview`);
}

export default AdvertAPI;
