import axios from 'axios';

import { ArtistDetailModel, ArtistModel } from '@/models/Artist';

class ArtistAPI {
  static getArtist = async ({ artistId }: { artistId?: string }) => await axios.get<ArtistModel>(`/artist/${artistId}`);

  static updateArtist = async ({
    artistId,
    artistDetails,
    similarArtists = false,
  }: {
    artistId: string;
    artistDetails: ArtistDetailModel;
    similarArtists?: boolean;
  }) => await axios.put<ArtistModel>(`/artist/${artistId}?similarArtists=${similarArtists}`, artistDetails);

  static searchGenre = async ({ searchValue = '' }: { searchValue: string }) =>
    await axios.get<{ genres: string[] }>(`/search/genre?term=${searchValue}&limit=50&offset=0`);
}

export default ArtistAPI;
