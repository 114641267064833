import axios from 'axios';

import {
  MeetTheCuratorModel,
  PlaylistPitchAccountItemsModel,
  PlaylistPitchAccountModel,
  PlaylistPitchPitchesModel,
  PlaylistPreviousPitchesAPIModel,
  PlaylistRecommendationsModel,
} from '@/models/Playlist';

class PlaylistAPI {
  static getPlaylistRecommendations = async ({ playlistRecommendationsId }: { playlistRecommendationsId: string }) =>
    await axios.get<PlaylistRecommendationsModel>(`playlist-recommendation/${playlistRecommendationsId}`);

  static getPreviousPlaylistPitches = async ({ accountId }: { accountId: string }) =>
    await axios.get<PlaylistPreviousPitchesAPIModel>(
      `playlist-recommendation/${accountId}/successful?limit=50&offset=0`
    );

  static getPlaylistRecommendationsByGenre = async ({
    accountId,
    limit,
    offset,
    sessionId = '',
  }: {
    accountId: string;
    limit?: number;
    offset?: number;
    sessionId?: string;
  }) =>
    await axios.get<PlaylistPreviousPitchesAPIModel>(
      `playlist/recommendation/${accountId}?limit=${limit}&offset=${offset}&sessionId=${sessionId}`
    );

  static createPlaylistPitchDraft = async ({
    accountId,
    artistId,
    playlistPitches,
    sessionId,
  }: {
    accountId: string;
    artistId: string;
    playlistPitches: PlaylistPitchPitchesModel[];
    sessionId?: string;
  }) => await axios.post<{ id: string }>('pitch/playlist/draft', { accountId, artistId, playlistPitches, sessionId });

  static getAccountPlaylistPitches = async ({
    accountId,
    status = '',
    sortedBy = '',
    pageSize = 10,
    continuationToken = '',
  }: {
    accountId: string;
    status?: string;
    sortedBy?: string;
    pageSize?: number;
    continuationToken?: string;
  }) =>
    await axios.get<PlaylistPitchAccountModel>(
      `pitch/playlist/${accountId}?status=${status}&sortedBy=${sortedBy}&pageSize=${pageSize}&continuationToken=${continuationToken}`
    );

  static getPlaylistPitch = async ({ accountId, playlistPitchId }: { accountId: string; playlistPitchId: string }) =>
    await axios.get<PlaylistPitchAccountItemsModel>(`/pitch/playlist/${accountId}/${playlistPitchId}`);

  static getMeetTheCurator = async ({ curatorSpotifyId }: { curatorSpotifyId: string }) =>
    await axios.get<MeetTheCuratorModel>(`curator/${curatorSpotifyId}`);
}

export default PlaylistAPI;
